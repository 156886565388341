import React, { useContext, useEffect, useState } from 'react';


const App = () => {
  return (
    <div>
      <h1>Calculator</h1>
    </div>
  );
}

export default App;