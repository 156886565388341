import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginFormComponent from './loginForm';
import OTPForm from './otpForm';
import { isMobile } from 'react-device-detect';

const LoginForm = ({ nextPage, userType, allowEmailOtp = false }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [loginData, setLoginData] = useState(null);

  console.log('isMobile', isMobile);

  const prevStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };
  const handleFormSubmit = newData => {
    setLoginData({ ...loginData, ...newData });
  };

  switch (step) {
    case 1:
      return (
        <div
          style={
            isMobile
              ? {
                  margin: 'auto',
                }
              : {
                  width: '400px',
                  margin: 'auto',
                }
          }>
          <LoginFormComponent
            stateName={'userData'}
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormSubmit={handleFormSubmit}
            userType={userType}
            allowEmailOtp={allowEmailOtp}
          />
        </div>
      );
    case 2:
      return (
        <div
          style={
            isMobile
              ? {
                  margin: 'auto',
                }
              : {
                  width: '400px',
                  margin: 'auto',
                }
          }>
          <OTPForm
            loginData={loginData}
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormSubmit={handleFormSubmit}
            userType={userType}
            allowEmailOtp={allowEmailOtp}
          />
        </div>
      );
    default:
      navigate(nextPage);
  }
};

export default LoginForm;
